export default [
  // {
  //   img: "/news/env-embr.jpg",
  //   title: {
  //     en: "New!",
  //     uk: "Новинка!",
  //   },
  //   text: {
  //     en:
  //       "DL envelopes with traditional Ukrainian embroidery are now available to order.",
  //     uk:
  //       "Конверти DL з традиційною українською вишивкою тепер доступні для замовлення.",
  //   },
  //   id: "EmbrEnvelopes",
  // },
  // {
  //   img: "/news/env-UA.jpg",
  //   title: {
  //     en: "Back in stock!",
  //     uk: "Знову в наявності!",
  //   },
  //   text: {
  //     en:
  //       "You asked - we did! Your favorite envelopes in patriotic style are available again on our website.",
  //     uk:
  //       "Ви просили - ми зробили! Ваші улюблені конверти у патріотичному стилі знову доступні у нас на сайті.",
  //   },
  //   id: "UAenvelopes",
  // },
  {
    img: "/news/nytorba.jpg",
    title: { en: "Print on paper bags", uk: "Друк на паперових торбах" },
    text: {
      en: "Digital printing on paper bags from 50 pcs.",
      uk:
        "Цифровий друк будь-якої складності та колірності на паперових торбинках від 50 штук.",
    },
    id: "digitalprinttorba",
  },
  {
    img: "/news/kuvert_25.jpg",
    title: {
      en: "It's our 25th birthday!",
      uk: "Нам 25 років!",
    },
    text: {
      en:
        "On December 3rd, we celebrate the 25th anniversary of our factory. We are grateful to our customers, partners and employees for these years together and looking forward to further successful cooperation. We also bow low to the Armed Forces of Ukraine for the opportunity to continue working.",
      uk:
        "3-го грудня ми святкуємо 25-ту річницю нашої фабрики. Вдячні клієнтам, партнерам та працівникам за ці роки разом і сподіваємось на подальшу плідну співпрацю. А також низький уклін ЗСУ за можливість працювати далі.",
    },
    id: "Kuvert25",
  },
  {
    img: "/news/nybanderole.jpg",
    title: {
      en: "Print on parcel packages",
      uk: "Друк на бандерольних пакетах",
    },
    text: {
      en: "Digital printing on parcel packages from 50 pcs.",
      uk:
        "Цифровий друк будь-якої складності та колірності на бандерольних пакетах від 50 штук.",
    },
    id: "digitalprintbanderole",
  },
]
